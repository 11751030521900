import React from 'react';
import Helmet from 'react-helmet';
import PropTypes from 'prop-types';
import { Header } from 'components';
import { Layout, Container } from 'layouts';

const About = center => (
  <Layout>
    <Helmet title={'Who am I ?'} />
    <Header title="Who am I ?">About</Header>
    <Container center={center}>
      <h2>先にSNSやGitHubのアカウント紹介</h2>
      <table>
        <tr>
          <th>Web Site</th>
          <th>URL</th>
        </tr>
        <tr>
          <td>Twitter</td>
          <td><a href="https://twitter.com/gammaaex" target="_blank">https://twitter.com/gammaaex</a></td>
        </tr>
        <tr>
          <td>GitHub</td>
          <td><a href="https://github.com/gammaaex" target="_blank">https://github.com/gammaaex</a></td>
        </tr>
      </table>

      <h2>概要</h2>
      <p>こんにちは。がんまぁEXです。自己紹介するとこんな感じです。</p>
      <table>
        <tr>
          <th>Question</th>
          <th>Answer</th>
        </tr>
        <tr>
          <td>趣味</td>
          <td>プログラミング、自作PC、ゲーム、お菓子作り、サイクリング</td>
        </tr>
        <tr>
          <td>好きな言語</td>
          <td>Kotlin</td>
        </tr>
        <tr>
          <td>得意な言語</td>
          <td>PHP</td>
        </tr>
        <tr>
          <td>得意なDB</td>
          <td>MySQL</td>
        </tr>
        <tr>
          <td>その他</td>
          <td>関係データベースの設計が得意です。</td>
        </tr>
      </table>

      <p>このブログはいわゆるエンジニア備忘録です。適当に記事を書いていくのでよろしくお願い致します。</p>
    </Container>
  </Layout>
);

export default About;

Container.propTypes = {
  center: PropTypes.object,
};
